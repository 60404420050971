import React from 'react';
import './App.css';
import { LargeCarousel } from './components/LargeCarousel.jsx';
import { Header } from './components/Header.jsx';
import { Bottom } from './components/Bottom.jsx';

export default class App extends React.Component {
  componentDidMount() {
    if (!window.location.href.includes('https') && !window.location.href.includes('localhost')) {
        const secureUrl = window.location.href.replace('http', 'https')
        window.location.replace(secureUrl);
    }
  }

  render() {
    return (
      <div className="App">
        <Header />
        <LargeCarousel />
        <Bottom />
      </div>
    );
  }
}
