import React from 'react';
import styled, { keyframes } from 'styled-components';
import arthurAndKaren from '../images/arthur-and-karen-ellison.jpg';
import arthurAndKarenMobile from '../images/arthurAndKarenMobile.jpg';
import { slideIn } from './animationHelpers';

const AboutWrapper = styled.div`
  width: ${window.innerWidth < 850 ? '100%' : '66.5'};
  padding-top: 20vh;
  background-color: white;
  height: 100vh;
  z-index: 10;
`

const Title = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  font-size: 3em;
  color: rgb(106,103,91);
  text-align: ${window.innerWidth < 850 ? 'center' : 'left'};
  margin-bottom: .5em;
  animation-name: ${slideIn};
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
`

const MobileTitle = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1.3em;
  color: rgb(224,143,94);
  text-align: ${window.innerWidth < 850 ? 'center' : 'left'};
  margin-bottom: 1em;
  animation-name: ${slideIn};
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
`

const Description = styled.div`
  color: rgb(106,103,91);
  padding-left: 5%;
  padding-right: 5%;
  width: ${window.innerWidth < 850 ? '90%' : '66.5'};
  text-align: ${window.innerWidth < 850 ? 'center' : 'left'};
  font-size: 1.5em;
  line-height: 1.3em;
  animation-name: ${slideIn};
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
`

const MobileDescription = styled.div`
  color: rgb(106,103,91);
  padding-left: 5%;
  padding-right: 5%;
  text-align: 'center'
  font-size: 1.5em;
  line-height: 1.3em;
  animation-name: ${slideIn};
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
`


const textStyles = () => {
  if (window.innerWidth < 850) {
    return {
      paddingTop: '2em',
    }
  } else {
    return {
      display: 'inline-block',
      marginLeft: '2em',
      position: 'absolute',
      paddingTop: '6em',
    }
  }
};

// const NextDescription = styled.div`
//   width: 90%;
//   font-size: 22px;
//   position: absolute;
//   margin-top: -4em;
//   color: black;
//   z-index: 10;
//   text-align: ${window.innerWidth < 850 ? 'center' : 'left'};
//   margin-left: 70%;
//   padding-right: 30%;
//   line-height: ${window.innerWidth < 850 ? '1.5em' : '2em'};
//   display: inline-block;
// `

// <NextDescription>
//   is a privately owned company which exclusively manages the real estate and other personal and solely owned investments of Arthur and Karen Ellison – and other assets which they are trustees of.
// </NextDescription>

const imageStyles = () => {
  return {
    display: 'inline-block',
    height: '25em',
    width: '25em',
    borderRadius: '50%',
    marginLeft: '-33.3%'
  }
}

const mobileImageStyles = () => {
  return { borderRadius: '50%', width: '80%', marginTop: '-3em' }
}

const description = () => {
  return (
    <><b>Ellison Management LLC</b> is a privately owned company which exclusively manages the real estate and other personal and solely owned investments of Arthur and Karen Ellison – and other assets which they are trustees of.</>
  )
}

export const FirstSlide = () => {
  const imageSource = window.innerWidth < 850 ? arthurAndKarenMobile : arthurAndKaren
  const styles = window.innerWidth < 850 ? mobileImageStyles() : imageStyles();

  return (
    <AboutWrapper>
      <img src={imageSource} style={styles}/>
      <div style={textStyles()}>
        {window.innerWidth < 850 ? <MobileTitle>About Ellison Management</MobileTitle> : <Title>About Ellison Management</Title>}
        {window.innerWidth < 850 ? <MobileDescription>{description()}</MobileDescription> : <Description>{description()}</Description>}
      </div>
    </AboutWrapper>
  )
}
