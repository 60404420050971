import styled from 'styled-components';
import arthur from '../images/arthur-ellison-headshot.jpg';
import { slideIn } from './animationHelpers';

const Title = styled.div`
  color: rgb(224,143,94);
  display: ${window.innerWidth < 850 ? 'block' : 'inline-block'};
`

const AboutWrapper = styled.div`
  width: ${window.innerWidth < 850 ? '100%' : '66.5'};
  padding-top: 20vh;
  background-color: white;
  height: 100vh;
  z-index: 10;
`

const imageStyles = () => {
  return {
    display: 'inline-block',
    height: '25em',
    width: '25em',
    borderRadius: '50%',
    border: '0.5em solid rgb(224,143,94)'
  }
}

const mobileImageStyles = () => {
  return { borderRadius: '50%', width: '80%', marginTop: '-3em', border: '0.5em solid rgb(224,143,94)' }
}


const Description = styled.div`
  margin-top: ${window.innerWidth < 850 ? '1em' : '5em'};
  color: rgb(106,103,91);
  font-size: 1.3em;
  line-height: 1.3em;
  display: ${window.innerWidth < 850 ? 'block' : 'inline-block'};
  padding-left: 5%;
  padding-right: 5%;
`

const description = () => {
  return (
    <Description>
      <Title>Arthur A Ellison</Title>
      <div>Ellison Management LLC</div>
      <div>1460 Kenesaw Avenue</div>
      <div>Knoxville, Tennessee 37919</div>
      <div>© Copyright 2021 Ellison Management LLC</div>
    </Description>
  )
}

export const CarouselPage = () => {
  const styles = window.innerWidth < 850 ? mobileImageStyles() : imageStyles();

  return (
    <AboutWrapper>
      <img src={arthur} style={styles}/>
      <Description>
        <Title>Arthur A Ellison</Title>
        <div>Ellison Management LLC</div>
        <div>1460 Kenesaw Avenue</div>
        <div>Knoxville, Tennessee 37919</div>
        <div>© Copyright 2021 Ellison Management LLC</div>
      </Description>
    </AboutWrapper>
  )
}
