import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
  margin-top: ${window.innerWidth < 850 ? '.5em' : '2em'};
  margin-left: ${window.innerWidth < 850 ? '.0' : '15%'};
  width: ${window.innerWidth < 850 ? '100%' : '80%'};
  position: absolute;
  font-weight: bold;
  font-size: 2em;
  z-index: 10;
  font-family: papyrus;
  color: #43464a;
  text-align: ${window.innerWidth < 850 ? 'center' : 'left'};
`

export const Header = () => {
  return (
      <Title>
        Ellison Management
      </Title>
  );
};
