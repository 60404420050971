import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import orientalPearlTowerShanghai from '../images/orientalPearlTowerShanghai.jpg';
import hongKongSkyline from '../images/hongKongSkyline.jpg';
import sanDiegoCalifornia from '../images/sanDiegoCalifornia.jpg'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import smokies from '../images/smokies.jpg'
import lanai from '../images/lanai.jpg'
import turtle from '../images/turtle.jpg'
import styled from 'styled-components'
import { CarouselImage } from './CarouselImage.jsx';
import { FirstSlide } from './FirstSlide.jsx';
import { CarouselPage } from './CarouselPage.jsx';

const description = 'The real estate assets have historically been located in the United States in the states of California, Hawaii and Tennessee. Mr. Ellison does extensively travel globally – having interest in investing in specific ventures of international peoples and cultures.'
const descriptionTwo = 'Since 2006, Ellison Management LLC has not been by legal definition - a trader business, conducting active business, providing either goods or services to others. And by Knox County, Tennessee and Knoxville, Tennessee City code has not needed a business license or has been subject to Personal Property Tax.'
const descriptionThree = <>"World-wide investment is the only way to have a truly balanced portfolio. It takes time, personal visits, travel and research to find the right properties that will fill out a profitable, secure investment portfolio." <div style={{marginLeft: '2em', fontSize: '0.8em'}}>-Arthur A Ellison, President & Founder, Ellison Management LLC</div></>

export const LargeCarousel = () => {
  return(
    <Carousel showArrows={false}
      showStatus={false}
      showIndicators={true}
      showThumbs={false}
      infiniteLoop={true}
      width={'100%'}
      autoPlay={true}
      interval={6000}
      transitionTime={500}
      emulateTouch={true}
      stopOnHover={false}>

      <FirstSlide />
      <CarouselImage image={hongKongSkyline} alt={'hong kong'} title={'Where We Are'} description={description} textDireciton={'right'} show={true}/>
      <CarouselImage image={orientalPearlTowerShanghai} alt={'Shang Hai'} title={'Where We Are'} description={description} textDireciton={'right'} show={true}/>
      <CarouselImage image={sanDiegoCalifornia} alt={'San Diego'} title={'Legal Entity'} description={descriptionTwo} textDireciton={'right'} show={true} />
      <CarouselImage image={smokies} alt={'smokies'} title={'Legal Entity'} description={descriptionTwo} textDireciton={'right'} show={window.innerWidth < 850}/>
      <CarouselImage image={lanai} alt={'lanai'} title={'Investments'} description={descriptionThree} textDireciton={'left'} show={true}/>
      <CarouselImage image={turtle} alt={'turtle'} title={'Investments'} description={descriptionThree} show={window.innerWidth < 850} />
      <CarouselPage />
    </Carousel>
  )
}
