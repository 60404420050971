import styled from 'styled-components';

const titleStyles = (direction) => {
  return {
    fontSize: '34px',
    marginTop: '4rem',
    position: 'absolute',
    display: 'inline-block',
    color: '#4a4c4f',
    zIndex: '10',
    paddingRight: '5%',
    width: direction === 'left' ? '40%' : '90%',
    textAlign: 'left',
    paddingLeft: direction === 'left' ? '15%' : '65%'
  }
}
const descriptionStyles = (direction) => {
  return {
    width: direction === 'left' ? '40%' : '95%',
    fontSize: '22px',
    position: 'absolute',
    marginTop: '6rem',
    color: '#4a4c4f',
    zIndex: '10',
    textAlign: 'left',
    paddingLeft: direction === 'left' ? '15%' : '65%',
    lineHeight: '1.5em',
    display: 'inline-block',
    paddingTop: '2em'
  }
}

const imageStyles = () => {
  if (window.innerWidth < 850) {
    return {
      height: '55vh',
      width: 'auto',
      display: 'inline-block',
    }
  };
};

const mobileTitleStyles = () => {
  return {
    fontSize: '28px',
    marginTop: '1rem',
    position: 'absolute',
    width: '100%',
    color: '#4a4c4f',
    zIndex: '10',
    textAlign: 'center',
  }
}

const mobileDescriptionStyles = () => {
  return {
    fontSize: '16px',
    position: 'absolute',
    marginTop: '2rem',
    color: '#4a4c4f',
    zIndex: '10',
    paddingTop: '2em',
    paddingLeft: '5%',
    paddingRight: '5%',
    lineHeight: '1.3em',
    color: 'rgb(224,143,94)',
  }
}

export const CarouselImage = ({image, alt, title, description, textDireciton, show}) => {
  return (
    <div style={{backgroundColor: 'white', height: '100%'}}>
      <img src={image} alt={alt} style={imageStyles()} />
      {show && <div style={window.innerWidth < 850 ? mobileTitleStyles() : titleStyles(textDireciton)}>
        {textDireciton === 'right' && title}
        </div>
      }
      {show && <div style={window.innerWidth < 850 ? mobileDescriptionStyles () : descriptionStyles(textDireciton)}>
        {description}
      </div>
    }
    </div>
  )
}
